import React from 'react'
import './Login.css'
import { Link, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react'
import back from "../images/back.json"

function Login() {
  return (
    <div className='h-full bg-blue-teal-gradient'>
    <div class="h-full bg-blue-teal-gradient dark:bg-gray-900">
  
  <div class="mx-auto">
      <div class="flex justify-center px-6 py-12">
          
          <div class="w-full xl:w-3/4 lg:w-11/12 flex">
              
              <div class="w-full h-auto bg-gray-400 dark:bg-gray-800 hidden lg:block lg:w-5/12 bg-cover rounded-l-lg
                  background-image">
                    <div className='absolute'><Link class="font-bold text-2xl" to="/">
            <Lottie className='h-20' title='go Home' animationData={back}/>
          </Link></div>
                  </div>
              
              <div class="w-full lg:w-7/12  bg-gray-200 dark:bg-gray-700 p-5 rounded-lg lg:rounded-l-none">
                  <h3 class="py-4 text-2xl text-center text-gray-800 dark:text-white">Login!</h3>
                  <form class="px-8 pt-6 pb-8 mb-4 bg-white dark:bg-gray-800 rounded">
                      
                      <div class="mb-4">
                          <label class="block mb-2 text-sm font-bold text-gray-700 dark:text-white" for="email">
                              Email
                          </label>
                          <input
                              class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 dark:text-white border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                              id="email"
                              type="email"
                              placeholder="Email"
                          />
                      </div>
                      <div class="mb-4 md:flex md:justify-between">
                          <div class="w-full mb-4 md:mr-2 md:mb-0">
                              <label class="block mb-2 text-sm font-bold text-gray-700 dark:text-white" for="password">
                                  Password
                              </label>
                              <input
                                  class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 dark:text-white border border-red-500 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                  id="password"
                                  type="password"
                                  placeholder="******************"
                              />
                            
                          </div>
                          
                      </div>
                      <div class="mb-6 text-center">
                          <button
                              class="w-full px-4 py-2 font-bold text-white bg-blue-teal-gradient rounded-full hover:bg-blue-700 dark:bg-blue-700 dark:text-white dark:hover:bg-blue-900 focus:outline-none focus:shadow-outline"
                              type="button"
                          >
                              Login 
                          </button>
                      </div>
                      <hr class="mb-6 border-t" />
                      <div class="text-center">
                          <a class="inline-block text-sm text-blue-500 dark:text-blue-500 align-baseline hover:text-blue-800"
                              href="#">
                              Forgot Password?
                          </a>
                      </div>
                      <div class="text-center">
                          <Link class="inline-block text-sm text-blue-500 dark:text-blue-500 align-baseline hover:text-blue-800"
                              to={"/signup"}>
                              Don't have an account? Signup!
                          </Link>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</div>
  </div>
  )
}

export default Login
