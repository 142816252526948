import logo from './logo.svg';
import { Outlet } from 'react-router-dom';

import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';

function App() {
  return (
    <>
      <Header/>
      <main className='min-h-[calc(100vh-120px)] mt-36 '>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default App;
